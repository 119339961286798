import { videoCallEntity } from '@/entities/video-call'

export function useStatus() {
  const store = videoCallEntity.model.useVideoCallStore()

  const isQueue = computed(() => store.status === 'queue')
  const isInit = computed(() => store.status === 'init')
  const isSearching = computed(() => store.status === 'searching')
  const isConnecting = computed(() => store.status === 'connecting')
  const isStarting = computed(() => store.status === 'starting')
  const isCallActive = computed(() => store.status === 'active')

  function statusRandomSet(status: videoCallEntity.types.VideoCallStatusRandom) {
    store.status = status
  }

  function statusPrivateSet(status: videoCallEntity.types.VideoCallStatusPrivate) {
    store.status = status
  }

  return {
    isInit,
    isQueue,
    isSearching,
    isConnecting,
    isStarting,
    isCallActive,
    statusRandomSet,
    statusPrivateSet,
  }
}
